<template>
    <b-card class="job-order-details-card p-1">
        <b-card-header
            class="job-order-details-card__header position-relative"
            header-class="p-0 mb-2"
        >
            <div class="job-order-details-card__title-container d-flex">
                <unicon name="tv-retro" width="20" height="20" />
                <h4 class="job-order-details-card__title ml-1 mb-0">
                    تفاصيل الوظيفة
                </h4>
            </div>  
            <div
                class="job-order-details-card__job-order-status-container d-flex align-items-center"
            >
                <div class="job-order-details-card__job-order-status-title d-flex">
                    حالة الوظيفة:
                </div>
                <StatusBadge
                    class="job-order-details-card__job-order-status ml-1"
                    :statusList="userJobStatus"
                    :selectedStatusNumber="jobOrderDto.jobStatus"
                />
            </div>
        </b-card-header>

        <div class="card-title-seperator" />

        <b-form class="mt-1">
            <b-row>
                <b-col md="6">
                    <b-row>
                        <b-col md="12">
                            <ek-input-text
                                :disabled="true"
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'اسم الفعالبة مطلوب',
                                    },
                                ]"
                                v-model="jobOrderDto.accountName"
                                label="اسم الفعالية"
                                name="accountName"
                            />
                        </b-col>

                        <b-col md="12">
                            <ek-input-text
                                :disabled="true"
                                label="النشاط التجاري"
                                name="commerialActivityName"
                                :value="jobOrderDto.commerialActivityName"
                            />
                        </b-col>
                            <b-col md="12">
                            <ek-input-text
                                :disabled="true"
                                label="اخر موعد تقديم"
                                v-model="jobOrderDto.deadLineDate"
                                name="deadLineDate"
                            />
                        </b-col>
                        <b-col md="12">
                            <ek-input-text
                                :disabled="true"
                                label="المدينة"
                                v-model="jobOrderDto.location"
                                name="location"
                            />
                        </b-col>
                    </b-row>
                </b-col>

                <b-col md="6">
                    <ek-input-text
                        :disabled="true"
                        label="المسمى الوظيفي"
                        placeholder="ادخل المسمى الوظيفي"
                        v-model="jobOrderDto.jobName"
                        name="title"
                    />
                    <div class="job-order-details-card__job-title-input-container">
                        <ek-input-textarea
                            class="job-order-details-card__job-title-input"
                            :disabled="true"
                            v-model="jobOrderDto.jobDiscription"
                            name="jobDiscription"
                        />
                        <div class="job-order-details-card__job-title-input-footer d-flex">
                            <h6 class="job-order-details-card__job-title-title mr-1 mb-0">رابط تقديم الوظيفة: </h6>
                            <a :href="jobOrderDto.extraUrl" class="job-order-details-card__job-title text-dark">{{jobOrderDto.extraUrl}}</a>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-form>

        <template #footer>
            <slot name="actions" v-bind="jobOrderDto"> </slot>
        </template>
    </b-card>
</template>

<script>
import { mapState} from "vuex";
import StatusBadge from "@global/components/StatusBadge";
export default {
    components: {
        StatusBadge,
    },
    computed: {
        ...mapState({
            userJobStatus: (state) => state.users.userJobStatus,
        }),
    },
    props: {
        jobOrderDto: Object
    }

};
</script>


<style lang="scss" scoped>

.job-order-details-card {

    &__job-title-input-container {
        position: relative;
        align-items: center;
        gap: 0.5rem;
    }
    
    &__job-title-input {
        &::v-deep {
            textarea {
                height: 246px;
                padding-bottom: 3.2rem !important;
            }
        }
    }

    &__job-title-input-footer {
        position: absolute;
        bottom: 1rem; 
        right: 1.5rem;
    }

    &__job-title-title {
        margin-top: 0.2rem;
    }

    &__job-title {
        text-decoration: underline;
    }
}

</style>