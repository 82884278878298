<template>
    <b-card v-if="userContactDto">
        <ul class="contact-information-card__list">
            <h2>معلومات التواصل</h2>
            <li class="contact-information-card__list-item text-right">
                <div class="contact-information-card__list-item d-flex">
                    <unicon name="graduation-cap"></unicon>
                    <div class="contact-information-card__list-item-title">
                        {{ userContactDto.lastScientificDegree }}
                    </div>
                </div>
            </li>
            <li class="contact-information-card__list">
                <div class="contact-information-card__list-item d-flex">
                    <unicon name="university"></unicon>
                    <div class="contact-information-card__list-item-title">
                        {{ userContactDto.schoolName }}
                    </div>
                </div>
            </li>
            <li class="contact-information-card__list-item text-right">
                <div class="contact-information-card__list-item d-flex">
                    <unicon name="calendar-alt"></unicon>
                    <div class="contact-information-card__list-item-title">
                        {{
                            new Date(
                                userContactDto.graduationDate
                            ).toLocaleDateString()
                        }}
                    </div>
                </div>
            </li>
            <li class="contact-information-card__list">
                <div class="contact-information-card__language-list-item d-flex" >
                    <unicon name="globe"></unicon>
                    <div class="contact-information-card__language-list-item-title d-flex flex-column">
                        <div v-for="(language, index) in userContactDto.languages" :key="index">
                            {{ language }}
                        </div>
                    </div>
                </div>
            </li>
            <li class="contact-information-card__list">
                <div class="contact-information-card__list-item d-flex">
                    <unicon name="bolt"></unicon>
                    <div class="contact-information-card__list-item-title">
                        {{ userContactDto.skills }}
                    </div>
                </div>
            </li>
        </ul>
    </b-card>
</template>

<script>
export default {
    props: {
        userContactDto: {
            type: Object,
        },
    },
};
</script>

<style lang="scss" scoped>

ul {
    padding: 0rem;
    margin: 0rem 0rem 2rem;
    list-style: none;
    li {
        margin-bottom: 1rem;
    }
}

.contact-information-card {
    &__list-item {
        align-items: center;
        gap: 1rem;
    }

    &__language-list-item {
        gap: 1rem;
    }

    &__language-list-item-title {
        font-size: 1.2rem;
    }

    &__list-item-title {
        font-size: 1.2rem;
        margin-top: 0.4rem;
    }
}
</style>
