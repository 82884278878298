<template>
    <ValidationObserver ref="jobOrderDetailsObserver">
        <UserJobForm :jobOrderDto="userJobDto" />
        <b-row>
            <b-col lg="4" md="6">
                <UserPersonalInformation
                    :userInformationDto="userJobDto.submitInfo.contactInfo"
                    :socialMedia="socialMedia"
                />
            </b-col>
            <b-col lg="5" md="6">
                <UserContactDetails :userContactDto="userJobDto.submitInfo" />
            </b-col>
            <b-col lg="3" md="12">
                <UserCvDownload :cvUrl="userJobDto.submitInfo.cvUrl" />
            </b-col>
        </b-row>
    </ValidationObserver>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import UserJobForm from "../components/userJobDetails/UserJobForm.vue";
import UserContactDetails from "../components/userJobDetails/UserContactDetails.vue";
import UserPersonalInformation from "../components/userJobDetails/UserPersonalInformation.vue";
import UserCvDownload from "../components/userJobDetails/UserCvDownload.vue";

export default {
    components: {
        UserJobForm,
        UserContactDetails,
        UserPersonalInformation,
        UserCvDownload,
    },
    props: {
        id: String,
        jobId: String,
        jobInfoId: String,
        userId: String,
    },
    computed: {
        ...mapState({
            userJobDto: (state) => state.users.userJobDto,
            socialMedia: (state) => state.settings.socialMedia,
        }),
    },
    created() {
        this.getUserJobDetails({
            id: this.id,
            jobId: this.jobId,
            jobInfoId: this.jobInfoId,
        });
    },
    beforeDestroy() {
        this.Reset_Job_Order_Dto();
    },
    methods: {
        ...mapActions(["getUserJobDetails"]),
        ...mapMutations(["Reset_Job_Order_Dto"]),
    },
};
</script>
