<template>
    <b-card class="personal-information-card">
        <ul class="personal-information-card__list">
            <h2>المعلومات الشخصية</h2>
            <li class="personal-information-card__list-item">
                <div class="personal-information-card__list-item d-flex">
                    <unicon name="envelope"></unicon>
                    <div class="personal-information-card__list-item-title">
                        {{ userInformationDto.email }}
                    </div>
                </div>
            </li>
            <li class="personal-information-card__list-item">
                <div class="personal-information-card__list-item d-flex">
                    <unicon name="forwaded-call"></unicon>
                    <div class="personal-information-card__list-item-title">
                        {{ userInformationDto.phoneNumber }}
                    </div>
                </div>
            </li>
            <li class="personal-information-card__list-item">
                <div class="personal-information-card__list-item d-flex">
                    <unicon name="calendar-alt"></unicon>
                    <div class="personal-information-card__list-item-title">
                        {{ new Date(userInformationDto.birthDate).toLocaleDateString() }}
                    </div>
                </div>
            </li>
            <li class="personal-information-card__list-item">
                <div class="personal-information-card__list-item d-flex">
                    <unicon name="chat-bubble-user"></unicon>
                    <div class="personal-information-card__list-item-title">
                        {{ genders[userInformationDto.gender - 1] }}
                    </div>
                </div>
            </li>
        </ul>
        <ul class="personal-information-card__list">
            <h2>المعلومات المهنية</h2>
            <li class="personal-information-card__list-item" v-for="(media, index) in userInformationDto.socialMedias" :key="index">
                <div class="personal-information-card__list-item d-flex">
                    <unicon :name="findSocialIcon(media)"></unicon>
                    <div class="personal-information-card__list-item-title">
                        <a class="personal-information-card__social-link text-dark" :href="media.content" > {{ media.content }} </a>
                    </div>
                </div>
            </li>
        </ul>
    </b-card>
</template>

<script>
export default {
    data: () => ({
        genders: ["ذكر", "انثى", "أخرى"],
    }),
    props: {
        userInformationDto: {
            type: Object,
        },
        socialMedia: Array
    },
    methods: {
        findSocialIcon(media) {
            return this.socialMedia.find(item => item.name.toLowerCase() == media.title.toLowerCase())?.icon
        }
    }
};
</script>

<style lang="scss" scoped>
ul {
    padding: 0rem;
    margin: 0rem 0rem 2rem;
    list-style: none;
    li {
        margin-bottom: 1rem;
    }
}

.personal-information-card {
    &__list-item {
        flex-direction: row-reverse;
        align-items: center;
        gap: 1rem;
    }

    &__list-item-title {
        font-size: 1.2rem;
        margin-top: 0.4rem;
    }
    
    &__social-link {
        
    }
}
</style>
