<template>
    <b-card class="user-cv-download-card">
        <h2 class="user-cv-download-card__header mb-3">السيرة الذاتية</h2>
        <div class="user-cv-download-card__download-area d-flex align-items-center flex-column">
            <unicon name="file-blank" height="70" width="70" />
            <b-button class="user-cv-download-card__download-button" variant="primary" :href="cvUrl">download</b-button>
        </div>
    </b-card>
</template>

<script>
export default {
    props: {
        cvUrl: String
    },
};
</script>

<style lang="scss" scoped>

.user-cv-download-card {
    height: 350px;
    &__download-area {
        gap: 1rem;
    }
    
    &__download-button {
        font-size: 1.2rem;
        padding: 1rem 2rem;
    }
}

</style>
